<template>
  <base-section id="intro" class="bac-section">
    <v-container>
      <div style="margin-bottom: 1.5em;">
        <p class="text-uppercase text-h6 font-weight-bold mb-5 text-left">
          SAFE HARBOR MARINAS PLANS TO DEMOLISH THE OLDEST BUILDING ON
          PLYMOUTH'S WATERFRONT which WILL DIMINISH THE CHARACTER OF OUR TOWN
          FOR LOCALS AND TOURISTS&nbsp;ALIKE.
        </p>
        <p
          class="base-body text-body-1 mx-auto grey--text text--darken-1 text-left"
        >
          This project will make major alterations and additions to the existing
          marine use area which will impact Plymouth's waterfront, the Bradford
          Historic District, and the Plymouth Bay Cultural District for
          generations.
        </p>
        <p
          class="base-body text-body-1 mx-auto grey--text text--darken-1 text-left"
        >
          There are numerous concerns about both the short- and long-term
          implications for our town and many unanswered questions.
        </p>
        <call-to-action />
        <p class="text-uppercase text-h6 font-weight-bold mt-9 text-left">
          We can move forward without demolishing our history
        </p>
        <p
          class="base-body text-body-1 mx-auto grey--text text--darken-1 text-left"
        >
          This website outlines many of the potential implications of this
          project, including increased flooding, environmental impacts on
          surrounding wetlands, public access to the waterfront, and destruction
          of a historical structure. In the meantime, if you would like to be
          part of the conversation and connect with your neighbors, please
          <a href="mailto:BradfordAreaCommission@gmail.com">email us</a>.
        </p>
      </div>
    </v-container>
  </base-section>
</template>

<script>
import CallToAction from "./CallToAction.vue";
export default {
  name: "Intro",
  components: {
    CallToAction
  },
  data: () => ({})
};
</script>

<style lang="scss">
#intro {
  padding: 2em 1em !important;
  img {
    width: 100%;
    max-width: 1000px;
    margin: 1em auto;
  }
  .container {
    max-width: 1000px;
  }
}
</style>
