<template>
  <div id="call-to-action" v-if="ready">
    <v-card color="#00b4d8">
      <v-card-title class="white--text mt-8 break-word">
        <p
          class="d-none d-sm-flex"
          style="font-size: 36px; letter-spacing: 0.5px; margin: 0 0 0 0.25em;"
        >
          <span>What has happened so far</span>
        </p>
      </v-card-title>

      <v-divider color="white"></v-divider>

      <v-card-text class="my-2 pl-8 pr-6">
        <!-- <div class="meeting-name"></div> -->
        <div class="meeting-details mt-0">
          <p>
            The entire focus of the Bradford Area Commission (BAC) has always
            been to find common ground with Safe Harbor Marinas (SHM).
            Thankfully, every Town Board we appeared in front of understood we
            were not (and never have been) NIMBY. Each and every Board directed
            SHM to work with the Bradford Area Commission.
          </p>
          <p>
            Here is a brief list of just some of the things that the BAC
            accomplished during this process since December 2021:
          </p>
          <ul>
            <li>
              The Old Foundry has been standing for 2+ years longer than it
              would have
            </li>
            <li>
              The Marina was prevented from building a 45' tall warehouse, which
              would have set a dangerous precedent for our historic downtown
            </li>
            <li>
              The Marina was prevented from building a 2,800 sq.ft. Marina
              Operations Building on public docks over the water
            </li>
            <li>
              The Town began to finally enforce Bylaw 206-2 (Floodplain Overlay
              District or FPOD) which requires any construction over 301 sq.
              feet in the Floodplain to go through the ZBA approval process.
              (Prior to us bringing this to the Town's attention at a PB
              meeting, this Bylaw had never been enforced. It now shows up on
              ZBA agendas frequently.)
            </li>
            <li>
              The Marina had to increase their safety measures regarding
              potential flooding of the surrounding homes. (There are still a
              lot of issues here, but it's a massive improvement.)
            </li>
            <li>
              Although there are still issues (a whole lotta issues!) with their
              plans, the current design is better than the original "Walmart on
              the Waterfront." We've maybe upgraded to a "Best Western on the
              Bay"?
            </li>
            <li>
              Our efforts early in the process brought to light that the cupola
              was potentially structurally saveable, despite SHM's assertions to
              the contrary, which has led to the cupola being saved
            </li>
            <li>
              The Marina had to improve their public access to water in order to
              better conform with State laws
            </li>
            <li>
              The Marina had to bring their parking into compliance a touch more
              (though again, not nearly enough)
            </li>
            <li>
              The Marina is no longer permitted to fuel on the street right
              across from an elementary school playground
            </li>
            <li>
              The Marina is no longer permitted to have a 24/7 fueling station
              (though they can still fuel 24/7, just not have a station)
            </li>
            <li>
              Following the ZBA's decision to allow the Marina to proceed with
              the demolition of the Old Foundry building, the Bradford Area
              Commission and others worked together behind the scenes to try to
              at least save the historic cupola on its roof. After much initial
              resistance, SHM eventually assented but did not wish for the event
              to be a public one. The cupola has been relinquished by them and
              taken away for restoration.
            </li>
            <li>
              As of Town Meeting in April 2024, the Marina is now firmly within
              the Historic District (as is the rest of the Bradford Area
              neighborhood including the yacht club and Nathaniel Morton school)
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
    <v-card color="#00b4d8" class="mt-4">
      <v-card-title class="white--text mt-8 break-word">
        <p
          class="d-none d-sm-flex"
          style="font-size: 36px; letter-spacing: 0.5px; margin: 0 0 0 0.25em;"
        >
          <span>Note from BAC Co-Chairs &ndash; December 21, 2023</span>
        </p>
      </v-card-title>

      <v-divider color="white"></v-divider>

      <v-card-text class="my-2 pl-8 pr-6">
        <!-- <div class="meeting-name"></div> -->
        <div class="meeting-details mt-0">
          <p>
            Over the course of this entire adventure, we had a 0-5 ZBA vote (in
            the neighborhood's favor) and a 5-0 ZBA vote (in the Marina's
            favor). And sadly, it was the 5-0 vote that closed the books on the
            process.
          </p>
          <p>
            The room was packed for that final vote on 12/18/23. It was packed
            with abutters, and precinct reps, and community members who care so
            deeply about what is happening on our historic waterfront.
          </p>
          <p>
            The Bradford Area Commission has spent thousands of hours (we
            stopped counting about a year ago!) tracking the project, trying to
            bridge compromise after compromise with the Marina, asking Town
            Boards to uphold our Bylaws, reviewing technical manuals and site
            plans, pointing out huge mistakes in the Marina's plans (like how
            they "forgot" to file a 98 page document that showed how they
            wouldn't pollute the waterways, hmmmm) and asking for their mistakes
            to be fixed. We even caucused at every precinct meeting, and spoke
            at Town Meeting, all unfortunately under falsehoods since - sadly -
            when the Marina completed their CPC application and said they would
            pursue preservation? They didn't actually intend to follow through
            with it.
          </p>
          <p>
            But still, we showed up. And we didn't let the process get us down!
          </p>
          <p>
            And all of YOU showed up too. Time and time and time again, ALL of
            the meetings regarding the Marina were a full house. You lobbied,
            you spoke out, you shared your concerns, YOU made a positive
            difference each and every time. And you also lifted each of us up,
            especially on the days we lost a bit of hope.
          </p>
          <p>
            And you know what else has happened? Our neighborhood has grown
            closer. There's an increased awareness of how our public process
            works (and also how it <em>doesn't</em> always work "for the little
            guy"). And the historic Old Foundry got to have another 2+ years and
            counting standing in her lovely little way on our waterfront.
          </p>
          <p>
            We'll keep you posted. In the meantime, thank you for everything
            you've done on behalf of our community!
          </p>
          <p>
            Best Wishes,<br />Jessica, John, Tim, and Kate<br />on behalf of the
            Bradford Area Commission Co-Founders
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CallToAction",
  data: () => ({
    ready: false,
    dateBefore: {},
    dateMeeting: {}
  }),
  methods: {
    popup() {
      const link = "article-9d";
      this.$hub.$emit("openPdf", link, "timeline");
    }
  },
  mounted() {
    this.dateAfter = this.moment("2023-06-06");
    this.ready = true;
  }
};
</script>

<style lang="scss">
#call-to-action {
  margin: 2em 0 1em;
  .meeting-name {
    font-size: 26px;
    font-weight: bold;
    line-height: 125%;
  }
  .meeting-date {
    font-size: 23px;
    font-weight: bold;
    line-height: 125%;
  }
  .meeting-details {
    margin: 1em 0;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 150%;
    a {
      color: white;
    }
  }
  .break-word {
    word-break: break-word !important;
  }
  .link {
    color: #03045e;
    font-weight: bold;
  }
}
</style>
